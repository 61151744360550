import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'
import bootstrap from 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'

createApp(App)
.use(router)
.use(VuePlyr)
.mount('#app')
