<template>
  <div class="appview">
    <Nav />
    <router-view/>
 
  </div>
</template>

<script>
import Nav from "@/components/Nav/Nav.vue";

export default {
  name: "App",
  components: {
    Nav,
  },
};

</script>

<style scoped>

.appview {
  background-color: #1d3557;
  color: #f1faee;
  height: 100dvh;
  
}

</style>
