<template>
  <nav class="navbar navbar-expand-lg">
    <div class="container-fluid">
      <router-link class="navbar-brand" to="/">FuckinSnitch.com</router-link>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation" ref="navbarToggler">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item">
            <router-link class="nav-link" to="/" v-on:click.native="closeNav">Home</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/snitch" v-on:click.native="closeNav">Snitches</router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "Nav",
  methods: {
    closeNav() {
      if (window.innerWidth <= 992) { // navbar collapses in smaller screens
        this.$refs.navbarToggler.click(); // simulate a click on the button
      }
    },
  },
};
</script>

<style scoped>
.navbar {
  background-color: #1d3557;
}

a {
  color: #f1faee;
  font-size: larger;
}

@media only screen and (max-width: 600px) {
  h1 {
    font-size: 50px;
  }
}

@media only screen and (max-width: 400px) {
  h1 {
    font-size: 30px;
  }
}
</style>