<template>
    <h1>Do you recognize this snitch?</h1>
    <div id="centered-carousel">
      <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-inner">
          <div class="carousel-item active" data-bs-interval="5000">
            <img :src="img1" class="d-block w-100" alt="Image 1" />
          </div>
          <div class="carousel-item" data-bs-interval="5000">
            <img :src="img2" class="d-block w-100" alt="Image 2" />
          </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  </template>
  
  <script>
  import img1 from '@/assets/CensoredRalph.jpg'
  import img2 from '@/assets/copralph.jpg'
  import { logEvent } from 'firebase/analytics';
    import { analytics } from '@/firebase/firebase.js';
  
  export default {
    name: "Snitches",
    created() {
      logEvent(analytics, "screen_view", {
        screen_name: "SnitchView",
        screen_class: "SnitchView",
      });
    },
    data() {
      return {
        img1,
        img2,
      };
    },
  };
  </script>
  
  <style scoped>
  #centered-carousel {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  h1 {
    font-size: 4em; /* base font-size */
    text-align: center;
    color: #f1faee;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .carousel {
    width: 60%;
    height: 200px;
  }
  
  .carousel img {
    width: 100%;
    max-height:50dvh;
    object-fit: contain;
  }
  
  @media (max-width: 767px) {
    h1 {
      font-size: 2em; /* smaller font-size for screens less than 767px */
    }
    
    .carousel {
      width: 90%; /* larger width for smaller screens */
    }
  }
  
  @media (min-width: 768px) and (max-width: 991px) {
    h1 {
      font-size: 2.5em; /* medium font-size for screens between 768px and 991px */
    }
    
    .carousel {
      width: 70%; /* medium width for medium screens */
    }
  }
  
  @media (min-width: 992px) {
    h1 {
      font-size: 4em; /* larger font-size for screens larger than 992px */
    }
    
    .carousel {
      width: 60%; /* smaller width for larger screens */
    }
  }
  </style>