// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCLDEco_ufcHg70vrqGnRslKxOF1rorP98",
  authDomain: "ralphjoke-d2c47.firebaseapp.com",
  projectId: "ralphjoke-d2c47",
  storageBucket: "ralphjoke-d2c47.appspot.com",
  messagingSenderId: "368851454365",
  appId: "1:368851454365:web:65bd8b2ba0d37b82d94774",
  measurementId: "G-XYYNC9W4QF"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);