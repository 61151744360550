<template>
  <h1>Snitch Files</h1>
  <div class="plyr__video-embed" id="player">
    <iframe
      src="https://www.youtube.com/embed/f44zb2aoB1s"
      allowfullscreen
      allowtransparency
      allow="autoplay"
    ></iframe>
  </div>
</template>

<script>
import { logEvent } from 'firebase/analytics';
import { analytics } from '@/firebase/firebase.js';

export default {
  name: "HelloWorld",
  created() {
    logEvent(analytics, "screen_view", {
      screen_name: "HomeView",
      screen_class: "HomeView",
    });
  },
};
</script>

<style scoped>
h1 {
  font-size: 4em; /* base font-size */
  text-align: center;
  color: #f1faee;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (max-width: 767px) {
  h1 {
    font-size: 2em; /* smaller font-size for screens less than 767px */
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  h1 {
    font-size: 2.5em; /* medium font-size for screens between 768px and 991px */
  }
}

@media (min-width: 992px) {
  h1 {
    font-size: 4em; /* larger font-size for screens larger than 992px */
  }
}

.plyr__video-embed {
  position: relative;
  width: 60%; /* Reduce the width */
  height: 0;
  padding-bottom: 33.75%; /* Adjust the padding-bottom to maintain the aspect ratio */
  margin: 0 auto; /* center the div */
  overflow: hidden;
}

iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (max-width: 767px) {
  .plyr__video-embed {
    width: 90%;
    padding-bottom: 50.625%; /* Adjust the padding-bottom to maintain the aspect ratio */
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .plyr__video-embed {
    width: 70%;
    padding-bottom: 39.375%; /* Adjust the padding-bottom to maintain the aspect ratio */
  }
}

@media (min-width: 992px) {
  .plyr__video-embed {
    width: 60%;
    padding-bottom: 33.75%; /* Adjust the padding-bottom to maintain the aspect ratio */
  }
}
</style>
