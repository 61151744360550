<template>
  <div class="snitchview">
    <Snitches />
  </div>
</template>

<script>
import Snitches from "@/components/Snitches/Snitches.vue";

export default {
  name: "SnitchView",
  components: {
    Snitches,
  },
};
</script>

<style scoped>
.snitchview {
  background-color: #1d3557;
  color: #f1faee;
}
</style>
